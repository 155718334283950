<template>
  <transition
    enter-active-class="fade-enter-transition"
    enter-from-class="fade-out"
    leave-active-class="fade-leave-transition"
    leave-to-class="fade-out"
  >
    <div v-if="hasLocations">
      <h3 class="tw-mb-3 section-title heading-color">
        {{ title }}
      </h3>
      <ul>
        <li
          v-for="location in locations"
          :key="location.id"
          class="tw-mb-3"
        >
          <Card
            :has-body-padding-small="showCards"
            :no-body-styles="!showCards"
          >
            <template #body>
              <div class="tw-flex tw-justify-between">
                <ResourceHeader
                  class="tw-items-center"
                  :image-url="location.imageUrl"
                  size="small"
                >
                  <template #title>
                    <NuxtLink
                      class="link-color-brand"
                      :to="location.url"
                    >
                      {{ location.name }}
                    </NuxtLink>
                  </template>
                </ResourceHeader>
                <SnowDataPoint>
                  {{ location.precipSnow }}
                </SnowDataPoint>
              </div>
            </template>
          </Card>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { formatSnow, safeRound } from '@@/utils/CommonUtils';
import { useLocationsStore } from '@@/stores/Locations';
import { useMetaStore } from '@@/stores/Meta';
import { useUserStore } from '@@/stores/User';
import { snowForecast5d } from '@@/utils/LocationSortFields';

export default {
  name: 'CompareTopForecasts',

  props: {
    titlePrefix: {
      type: String,
      default: 'Top',
    },
    days: {
      type: Number,
      default: 5,
    },
    limit: {
      type: Number,
      default: 5,
    },
    showCards: {
      type: Boolean,
      default: false,
    },
    compareType: {
      type: String,
      default: 'global',
      validator(value) {
        return ['nearby', 'global', 'regions', 'countries', 'states', 'season-passes', 'daily-reads'].includes(value);
      },
    },
    compareId: {
      type: String,
      default: 'all',
    },
  },

  data() {
    return {
      locations: [],
    };
  },

  computed: {
    ...mapState(useMetaStore, ['getLocationTypeBySlug']),

    ...mapState(useUserStore, {
      units: (state) => state.preferences.units,
    }),

    hasLocations() {
      return this.locations.length > 0;
    },

    title() {
      return `${this.titlePrefix} ${this.days}-Day Forecasts`;
    },
  },

  watch: {
    units() {
      this.fetchLocations();
    },
  },

  mounted() {
    this.fetchLocations();
  },

  methods: {
    ...mapActions(useLocationsStore, ['fetchForecastSnowSummary']),

    async fetchLocations() {
      /* eslint camelcase: off */
      try {
        const { locations } = await this.fetchForecastSnowSummary({
          compareType: this.compareType,
          compareId: this.compareId,
          days: this.days,
          exclude_zeros: false,
          lightWeight: true,
          limit: this.limit,
          location_type_ids: [
            this.getLocationTypeBySlug('lift-served-ski').id,
          ],
          saveToStore: false,
          sort: snowForecast5d.field,
          units: this.units,
        });

        this.locations = locations.map((location) => {
          const {
            id,
            name,
            image_url,
            slug,
          } = location;

          const { precip_snow } = location.forecast_snow_summary[0];

          return {
            id,
            name,
            imageUrl: image_url,
            precipSnow: formatSnow(safeRound(precip_snow), this.units, true),
            url: `/location/${slug}`,
          };
        });
      }
      catch (e) {
        // Do nothing, if locations cannot be fetched then just don't render the component.
      }
    },
  },
};
</script>
